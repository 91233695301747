import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from '../app/components/app/app.component';
import { LoginComponent } from './components/login/login.component';
import { ConsentComponent } from './components/consent/consent.component';
import { ErrorComponent } from './components/error/error.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginFormComponent } from './components/login/login-form/login-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericModalComponent } from './components/generic-modal/generic-modal.component';
import { ErrorPopupComponent } from './components/error-popup/error-popup.component';
import { ChooseBrandModalComponent } from './components/choose-brand-modal/choose-brand-modal.component';
import { ValidatePageComponent } from './components/validate-page/validate-page.component';
import { LoaderComponent } from './components/loader/loader.component';
import { A11yModule } from '@angular/cdk/a11y';
import { HttpClientModule } from '@angular/common/http';

//SERVICES
import { WindowRef } from './services/window.service';
import { SDKFactory } from './services/sdk-factory.service';
import { ApiConfigService } from './services/api-config.service';
import { GlobalService } from './services/global.service';
import { UtilityService } from './services/utility.service';
// import { ProxyPageComponent } from './components/proxy-page/proxy-page.component';
import { ResourceService } from './services/resources.service';
import { ReplacePipe } from './pipes/replace.pipe';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ConsentComponent,
    ErrorComponent,
    HeaderComponent,
    FooterComponent,
    LoginFormComponent,
    GenericModalComponent,
    ErrorPopupComponent,
    ChooseBrandModalComponent,
    // ProxyPageComponent,
    ValidatePageComponent,
    LoaderComponent,
    ReplacePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    A11yModule,
    HttpClientModule,
  ],
  providers: [
    WindowRef,
    SDKFactory,
    ApiConfigService,
    GlobalService,
    UtilityService,
    ResourceService,
    {
      provide: APP_INITIALIZER,
      useFactory: resourceProviderFactory,
      deps: [ResourceService, UtilityService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function resourceProviderFactory(
  provider: ResourceService,
  utilityService: UtilityService
) {
  return async () => {
    utilityService.showLoader();
    await provider.loadConfig();
    await provider.loadLabels();
    utilityService.hideLoader();
  };
}
