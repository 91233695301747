import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { SDKFactory } from '../services/sdk-factory.service';
import { UtilityService } from '../services/utility.service';

export const sdkInit = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  sdkService: SDKFactory = inject(SDKFactory),
  utilityService: UtilityService = inject(UtilityService)
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    // console.debug('can activate start', route.toString(), this.sdkService.initialized);
    utilityService.showLoader();

    const partner = sessionStorage.getItem('partner');
    if (!sdkService.initialized && partner) {
      // console.debug('can activate initialize');
      sdkService
        .initSDK()
        .then(() => {
          utilityService.hideLoader();
          resolve(true);
        })
        .catch((error) => {
          console.error('Unable to init SDK', error);
          utilityService.hideLoader();
          reject(false);
        });
    } else {
      utilityService.hideLoader();
      resolve(true);
    }
  });
  // // console.debug('can activate start', route.toString(), this.sdkService.initialized);
  // if (!sdkService.initialized) {
  //   // console.debug('can activate initialize');
  //   try {
  //     await sdkService.initSDK();
  //   } catch (error) {
  //     return false;
  //   }
  // }
  // // console.debug('can activate end', route.toString(), this.sdkService.initialized);
  // return true;
};
