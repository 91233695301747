import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject, Observable, filter, catchError, of } from 'rxjs';
import { UtilityService } from './utility.service';
import { environment } from './../../environments/environment';
import { brandsStyles } from '../interfaces/BrandStyles';

@Injectable()
export class ResourceService {
  private config: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private labels: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private utilityService: UtilityService
  ) {}

  saveConfig(value: any) {
    this.config.next(value);
  }

  saveLabels(value: any) {
    this.labels.next(value);
  }

  getConfig(): Observable<any> {
    return this.config.asObservable().pipe(filter((value) => value !== null));
  }

  getLabels(): Observable<any> {
    return this.labels.asObservable().pipe(filter((value) => value !== null));
  }
  // getConfig(): any {
  //   return this.config.getValue();
  // }

  loadConfig() {
    const brand = this.assignBrand();
    const region = this.utilityService.getRegionFromPath();

    const partner = sessionStorage.getItem('partner');

    if (region && brand && partner && !this.config.value) {
      const path =
        `${environment.cmsDomain}/exve.config.${region}.${brand}.${partner}.json`.toLowerCase();
      return new Promise((resolve, reject) => {
        this.http
          .get<any>(path)
          .pipe(
            catchError(
              (error: any, caught: Observable<any>): Observable<any> => {
                console.log('Load config error: ', error);
                return of();
              }
            )
          )
          .subscribe((res) => {
            this.saveConfig(res);
            resolve(true);
          });
      });
    } else {
      return null;
    }
  }

  loadLabels(forceLoad?: boolean, newCountry?: string, newLanguage?: string) {
    let brand = this.assignBrand();
    // let brand = this.utilityService.getBrandFromHost().toLowerCase();
    // if (!brand) {
    //   brand = sessionStorage.getItem('brand') || '';

    //   if (!brand || !Object.keys(brandsStyles).includes(brand.toLowerCase())) {
    //     brand = 'stellantis';
    //   }
    // } else {
    //   sessionStorage.setItem('brand', brand);

    //   // if (window.location.href.includes('/validate?')) {
    //   //   brand = 'stellantis';
    //   // }
    // }

    let region = this.utilityService.getRegionFromPath();
    const params = this.utilityService.getQueryParamsFromUrl();
    let partner = sessionStorage.getItem('partner');

    let country: string;
    let language: string;

    if (
      (!params || (!params['language'] && !params['country'])) &&
      !newCountry &&
      !newLanguage
    ) {
      country = sessionStorage.getItem('country') || '';
      language = sessionStorage.getItem('language') || '';
    } else {
      if (newCountry) {
        country = newCountry;
      } else {
        country = params['country'];
      }

      if (newLanguage) {
        language = newLanguage;
      } else {
        language = params['language'];
      }

      sessionStorage.setItem('country', country);
      sessionStorage.setItem('language', language);
    }

    if (!partner) {
      partner = 'exve';
      region = 'default';
      brand = 'stellantis';
    }

    if (!language) {
      country = 'gb';
      language = 'en';
    }
    
    if (language && !country) {
      if (region == 'default') {
        country = 'gb';
        language = 'en';
      } 
      
      else {
        switch (language) {
          case 'en':
            country = region === 'nafta' ? 'us' : 'gb';
            break;
          case 'it':
            country = 'it';
            break;
          case 'fr':
            country = region === 'nafta' ? 'ca' : 'fr'
            break;
          case 'es':
            country = region === 'nafta' ? 'us' : 'es'
            break;
          case 'de':
            country = 'de';
            break;
          case 'nl':
            country = 'nl';
            break;
          case 'cs':
            country = 'cz';
            break;
          case 'dk':
            country = 'da';
            break;
          case 'fi':
            country = 'fi';
            break;
          case 'el':
            country = 'gr';
            break;
          case 'hu':
            country = 'hu';
            break;
          case 'no':
            country = 'no';
            break;
          case 'pl':
            country = 'pl';
            break;
          case 'pt':
            country = 'pt';
            break;
          case 'sk':
            country = 'sk';
            break;
          case 'sv':
            country = 'se';
            break;
          default:
            country = 'gb';
            language = 'en';
            break;
        }
      }
    }

    if (
      region &&
      brand &&
      language &&
      country &&
      partner &&
      (!this.labels.value || forceLoad)
    ) {
      const path = `${environment.cmsDomain}/exve.labels.${region}.${brand}.${partner}.${country}.${language}.json`.toLowerCase();
      const path_fallback = `${environment.cmsDomain}/exve.labels.${region}.${brand}.${partner}.gb.en.json`.toLowerCase();

      return new Promise((resolve, reject) => {
        this.http
          .get<any>(path)
          .pipe(
            catchError(
              (error: any, caught: Observable<any>): Observable<any> => {
                console.log('Load labels error 1: ', error);
                
                this.http
                  .get<any>(path_fallback)
                  .pipe(
                    catchError(
                      (error: any, caught: Observable<any>): Observable<any> => {
                        console.log('Load labels error 2: ', error);
                        return of();
                      }
                    )
                  )
                  .subscribe((res) => {
                    this.saveLabels(res);
                    resolve(true);
                  });

                return of();
              }
            )
          )
          .subscribe((res) => {
            this.saveLabels(res);
            resolve(true);
          });
      });
    } else {
      return null;
    }
  }

  assignBrand() {
    let brand = this.utilityService.getBrandFromQueryParams().toLowerCase();
    if (!brand) {
      brand = sessionStorage.getItem('brand') || '';

      if (!brand || !Object.keys(brandsStyles).includes(brand.toLowerCase())) {
        brand = 'stellantis';
      }
    } else {
      sessionStorage.setItem('brand', brand);
    }
    return brand;
  }
}
