import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace',
})
export class ReplacePipe implements PipeTransform {
  transform(
    value: string,
    replaceKeys: string[],
    replaceValues: string[]
  ): any {
    let res = value;

    if (
      replaceKeys &&
      replaceValues &&
      replaceKeys.length &&
      replaceValues.length &&
      replaceKeys.length === replaceValues.length
    ) {
      replaceKeys.forEach((item: string, index: number) => {
        res = res.replaceAll(item, replaceValues[index]);
      });
    }

    return res;
  }
}
