import { Component, OnInit } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UtilityService } from 'src/app/services/utility.service';
import { ActivatedRoute, Params } from '@angular/router';
import {
  catchError,
  filter,
  first,
  firstValueFrom,
  of,
  throwError,
  Observable,
} from 'rxjs';
import { ResourceService } from './../../services/resources.service';
import { ErrorItem } from 'src/app/interfaces/ApplicationState';

@Component({
  selector: 'app-validate-page',
  templateUrl: './validate-page.component.html',
  styleUrls: ['./validate-page.component.scss'],
})
export class ValidatePageComponent implements OnInit {
  // config: any;
  isValidated: boolean = false;

  constructor(
    private http: HttpClient,
    private utilityService: UtilityService,
    private route: ActivatedRoute,
    private resourceService: ResourceService
  ) {}

  async ngOnInit() {
    sessionStorage.clear();

    this.utilityService.showLoader();

    const params: Params = await firstValueFrom(this.route.params);

    if (params && params['region']) {
      const region = params['region'];
      sessionStorage.setItem('region', region);

      const {language, country, appId, scope, redirect_uri, brand, state } =
        this.utilityService.getQueryParams();
      if (language && country && appId && scope && redirect_uri) {
        this.saveValidateParams(
          appId.trim(),
          redirect_uri.trim(),
          country,
          language,
          brand,
          scope,
          state
        );
        const body = {
          appId: appId.trim(),
          scope: JSON.parse(scope),
          redirectUri: redirect_uri.trim(),
        };

        let locale = 'en_GB';
        if (language && country) {
          locale = language + '_' + country.toUpperCase();
        }

        let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-originator-type': 'web',
            locale: locale,
          }),
        };

        const sdprUrl = environment.sdpr[region];

        this.http
          .post(sdprUrl + environment.sdpr.validatePath, body, httpOptions)
          .pipe(
            catchError(
              (error: any, caught: Observable<any>): Observable<any> => {
                this.utilityService.hideLoader();
                this.goToErrorPage('validation_error');
                return of();
              }
            )
          )
          .subscribe((response: any) => {
            this.utilityService.hideLoader();

            if (response.ValidationOutcome == 'success') {
              sessionStorage.setItem('partner', response.applicationName);

              this.isValidated = true;

              this.resourceService.getConfig().subscribe((res) => {
                if (res && res.config && this.isValidated) {
                  sessionStorage.setItem('apiKey', res.config.gigya_api_key);
                  location.href = res.config.gigyaAuthorizeEndpoint;
                }
              });
              this.resourceService.loadConfig();
            } else {
              this.goToErrorPage('validation_error');
            }
          });
      } else {
        this.utilityService.hideLoader();
        this.goToErrorPage(
          'validation_parameters_error',
          language == null || country == null
        );
      }
    } else {
      this.utilityService.hideLoader();
      this.goToErrorPage('validation_region_error');
    }
  }

  goToErrorPage(errorKey: string, noLocalization?: boolean) {
    if (noLocalization) {
      sessionStorage.setItem('country', 'gb');
      sessionStorage.setItem('language', 'en');
    }

    this.resourceService.getLabels().subscribe((res) => {
      if (res && res.labels) {
        const labels = res.labels;
        // let error = labels.errors[errorKey];
        let errorTitle = labels['errors_' + errorKey + '_title'];
        let errorMessage = labels['errors_' + errorKey + '_message'];

        if (!errorTitle) {
          errorTitle = labels.errors_generic_error_title;
        }
        if (!errorMessage) {
          errorMessage = labels.errors_generic_error_message;
        }

        this.utilityService.goToErrorPage({
          title: errorTitle,
          message: errorMessage,
          icon: {
            type: 'error',
            class: 'ico-alert',
          },
        });

        sessionStorage.removeItem('country');
        sessionStorage.removeItem('language');
      }
    });
  }

  saveValidateParams(
    appId: string,
    redirect_uri: string,
    country: string,
    language: string,
    brand: string,
    scopes: string,
    state: string
  ): void {
    sessionStorage.setItem('appId', appId);
    sessionStorage.setItem('redirect_uri', redirect_uri);
    sessionStorage.setItem('country', country);
    sessionStorage.setItem('language', language);
    sessionStorage.setItem('state', state);
    sessionStorage.setItem('scopes', JSON.parse(scopes));
    if (brand) {
      sessionStorage.setItem('brand', brand);
    }
  }
}
