import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'exve1-accountlinking';

  constructor(private utilityService: UtilityService) {}

  ngOnInit() {
    if (!window.location.href.includes('/validate?')) {
      this.utilityService.setBrandStyle();
    }
  }
}
