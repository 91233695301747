<div
  cdkTrapFocus
  class="modal fade"
  tabindex="-1"
  role="dialog"
  id="error-popup"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  [ngClass]="{ show: utilityService.showErrorPopup }"
>
  <div class="error-popup" role="document">
    <div class="popup-content">
      <div class="popup-alert-level-container">
        <span
          class="icon {{ utilityService.modalItems?.icon }}"
          [ngClass]="{
            alert: utilityService.modalItems?.level === 'alert',
            error: utilityService.modalItems?.level === 'error'
          }"
          *ngIf="utilityService.modalItems?.icon"
        ></span>
      </div>
      <div class="popup-message font-subtitle">
        <span [innerHTML]="utilityService.modalItems?.content"></span>
      </div>

      <div class="popup-button">
        <button
          #closeBtn
          type="button"
          class="btn btn-img"
          [attr.aria-label]="utilityService.modalItems?.buttons?.close"
          (click)="closeErrorPopup(false)"
        >
          <span class="ico-close"></span>
          {{ utilityService.modalItems?.buttons?.close }}
        </button>
      </div>
    </div>
  </div>
</div>
