import { Injectable } from '@angular/core';
import { WindowRef } from './window.service';
import { GlobalService } from './global.service';
import space from '@sp400/sdk';
import { FCABrand, MonitoringLevel, SpaceConfig } from '@sp400/core';
import { ApiConfigService } from './api-config.service';
import { firstValueFrom } from 'rxjs';
// import { environment } from '../../environments/environment';
import { UtilityService } from './utility.service';
import {
  AccountLinkingConsentData,
  AccountLinkingData,
  UserProfile,
} from '../interfaces/ApplicationState';
import { ResourceService } from './resources.service';

@Injectable({
  providedIn: 'root',
})
export class SDKFactory {
  currentBrand: FCABrand = 'jeep';
  currentCountry: string = 'gb';
  currentLanguage: string = 'en';
  monitoring: MonitoringLevel = 'HIGH';
  release_version: string = '0.0.1';
  window: any = this.windowRef.nativeWindow();
  initialized: boolean = false;
  config: any;
  ngZone: any;
  cognitoSdprParams: any = this.apiConfigService.config.cognito_sdpr_params;
  mopApiUrl: string = this.apiConfigService.api.mop.url;

  constructor(
    private windowRef: WindowRef,
    private globalService: GlobalService,
    private apiConfigService: ApiConfigService,
    private utilityService: UtilityService,
    private resourceService: ResourceService
  ) {}

  async initSDK(): Promise<boolean> {
    try {
      if (!this.config) {
        this.config = await firstValueFrom(this.resourceService.getConfig());
      }
      console.log(this.config);

      // const brand = this.utilityService.getBrandFromHost().toLowerCase();
      const brand = 'jeep';
      const region = sessionStorage.getItem('region');
      const country = sessionStorage.getItem('country');
      const language = sessionStorage.getItem('language');

      if (this.config?.config && region && country && language) {
        const spaceConfig: SpaceConfig = this.utilityService.getSpaceConfig(
          this.config,
          brand,
          region,
          country,
          language,
        );
        this.initialized = await space.initialize(spaceConfig);
      }

      if (this.initialized) {
        try {
          this.checkLoginAuthentication();
        } catch (error) {
          console.error('error checkLoginAuthentication', error);
        }
      }
    } catch (err) {
      console.error('error Init SDK', err);
      this.initialized = false;
    }

    return this.initialized;
  }

  async checkLoginAuthentication() {
    try {
      const getProfileRes = await this.getProfile();

      if (getProfileRes) {
        if (this.window.location.href.includes('login')) {
          this.utilityService.afterLoginSuccess();
        }
      }
    } catch (err) {
      console.error('User not logged in', err);
    }
  }

  async getProfile(
    refreshProfile: boolean = false
  ): Promise<UserProfile | undefined> {
    let userProfile;

    if (!this.globalService.hasProp('userProfile') || refreshProfile) {
      // I'll do the call for the first time
      try {
        ({ data: userProfile } = await space.get('authentication.profile', {}));
        this.globalService.setProp<UserProfile | undefined>(
          'userProfile',
          userProfile
        );
      } catch (error: any) {
        if (error.status == 403005) {
          console.error('User is not logged in', error);
        } else {
          console.error('Unable to get profile', error);
        }
      }
    } else {
      // Already present at least the first call
      userProfile = await firstValueFrom(
        this.globalService.getProp<UserProfile>('userProfile')
      );
    }

    return userProfile;
  }

  async login(loginID: string, password: string): Promise<boolean> {
    let { status, namespace, errors, data } = await space.set(
      'authentication.login',
      { loginID, password }
    );

    return data.status == 'OK';
  }

  async socialLogin(provider: string) {
    return await space.set('authentication.socialLogin', { provider });
    // this.windowRef.nativeWindow().gigya.accounts.socialLogin(
    //   {
    //     provider,
    //     callback: (res: any) => {
    //       console.log('responseeeeeeeeeeeeee', res);
    //       return res;
    //     }
    //   });
  }

  // async setProfile(data: AccountLinkingSetProfile<`$environment.OIDCKey`>) { // Is it possible to find a way to set the argument?
  async setProfile(data: any) {
    const { data: res } = await space.set('authentication.profile', data);
    return res;
  }

  // async postAccountLinking(data: AccountLinkingData): Promise<AccountLinkingResponse | undefined> {
  async postAccountLinking(data: AccountLinkingData): Promise<any> {
    const { data: res } = await space.set('sdpr.postAccountLinking', data);
    return res;
  }

  async postAccountLinkingConsent(data: AccountLinkingConsentData) {
    const { data: res } = await space.set(
      'sdpr.postAccountLinkingConsent',
      data
    );
    return res;
  }
}
