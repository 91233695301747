export const brandsStyles: any = {
  jeep: {
    '--primary-color': '#000000',
    '--secondary-color': '#FFFFFF',
    '--brand-color': '#f7941d',
    '--header-back-color': '#000000',
    '--header-color': '#FFFFFF',
  },
  fiat: {
    '--primary-color': '#000000',
    '--secondary-color': '#FFFFFF',
    '--brand-color': '#2c818c',
    '--brand-font-family': 'Poppins',
  },
  lancia: {
    '--primary-color': '#000000',
    '--secondary-color': '#FFFFFF',
    '--brand-color': '#2c818c',
    '--brand-font-family': 'Montserrat',
  },
  fiatprofessional: {
    '--primary-color': '#000000',
    '--secondary-color': '#FFFFFF',
    '--brand-color': '#9c1019',
  },
  alfaromeo: {
    '--primary-color': '#000000',
    '--secondary-color': '#FFFFFF',
    '--brand-color': '#ba0816',
    '--brand-font-family': 'Sequel100Black45',
    '--default-font-family': 'PSAGroupedHMISans',
  },
  abarth: {
    '--primary-color': '#000000',
    '--secondary-color': '#FFFFFF',
    '--brand-color': '#E72020',
    '--header-back-color': '#000000',
    '--header-color': '#FFFFFF',
    '--brand-font-family': 'Abarth',
  },
};
