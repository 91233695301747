<div
  cdkTrapFocus
  class="modal fade"
  tabindex="-1"
  role="dialog"
  id="generic-modal"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  [ngClass]="{ show: utilityService.showModal }"
>
  <div class="modal-dialog generic-modal" role="document">
    <div class="modal-content">
      <button
        #closeBtn
        type="button"
        class="btn btn-img close"
        (click)="closeModal(false)"
        [attr.aria-label]="utilityService.modalItems?.buttons?.close"
        aria-label=""
      >
        <span aria-hidden="true" class="ico-close"></span>
      </button>
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">
          {{ utilityService.modalItems?.title }}
        </h2>
      </div>
      <div class="modal-body">
        <span
          class="icon {{ utilityService.modalItems?.icon }}"
          [ngClass]="{
            alert: utilityService.modalItems?.level === 'alert',
            error: utilityService.modalItems?.level === 'error'
          }"
          *ngIf="utilityService.modalItems?.icon"
        ></span>
        <p class="font-subtitle">{{ utilityService.modalItems?.content }}</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          [attr.aria-label]="utilityService.modalItems?.buttons?.close"
          (click)="closeModal(false)"
        >
          {{ utilityService.modalItems?.buttons?.close }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          *ngIf="utilityService.modalItems?.buttons?.ok"
          [attr.aria-label]="utilityService.modalItems?.buttons?.ok"
          (click)="closeModal(true)"
        >
          {{ utilityService.modalItems?.buttons?.ok }}
        </button>
      </div>
    </div>
  </div>
</div>
