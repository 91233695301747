<div class="container login-wrapper">
  <div class="row">
    <div class="col-xl-9 col-lg-10 col-md-12 p-0">
      <div class="card border-0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h4 class="login-wrapper-title">
                {{ labels.login_account_form_title }}
              </h4>
            </div>
          </div>
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-floating">
              <input
                type="email"
                class="form-control"
                id="email"
                formControlName="email"
                placeholder="Email address"
              />
              <label for="email" class="form-label">{{
                labels.login_account_form_email
              }}</label>

              <div
                class="ctrl-error-container"
                *ngIf="
                  loginForm.get('email')?.touched ||
                  loginForm.get('email')?.dirty
                "
              >
                <span
                  *ngIf="loginForm.get('email')?.hasError('pattern')"
                  class="text-danger"
                  >{{ labels.login_account_form_email_format_error }}</span
                >
                <span
                  *ngIf="loginForm.get('email')?.hasError('required')"
                  class="text-danger"
                  >{{ labels.login_account_form_email_required_error }}</span
                >
              </div>
            </div>
            <div class="form-floating">
              <input
                type="{{ pwdInputType }}"
                class="form-control"
                id="password"
                formControlName="password"
                placeholder="Password"
              />
              <label for="password" class="form-label">{{
                labels.login_account_form_password
              }}</label>
              <button
                class="btn btn-img"
                type="button"
                [ngClass]="{ active: pwdInputType === 'text' }"
                (click)="togglepPwdShow($event)"
                [title]="
                  pwdInputType === 'text'
                    ? labels.login_account_form_hide_password
                    : labels.login_account_form_show_password
                "
                [attr.aria-label]="
                  pwdInputType === 'text'
                    ? labels.login_account_form_hide_password
                    : labels.login_account_form_show_password
                "
              >
                <span class="ico-eye"></span>
              </button>

              <div
                class="ctrl-error-container"
                *ngIf="
                  loginForm.get('password')?.touched ||
                  loginForm.get('password')?.dirty
                "
              >
                <span
                  *ngIf="loginForm.get('password')?.hasError('pattern')"
                  class="text-danger"
                  >{{ labels.login_account_form_password_format_error }}</span
                >
                <span
                  *ngIf="loginForm.get('password')?.hasError('required')"
                  class="text-danger"
                  >{{ labels.login_account_form_password_required_error }}</span
                >
              </div>
            </div>
            <div class="buttons-container">
              <div
                role="checkbox"
                class="toggler-checkbox {{ checkboxState.text }}"
                [title]="labels.login_form_keep_signed_in"
                [attr.aria-label]="labels.login_form_keep_signed_in"
                [attr.aria-checked]="checkboxState.active"
              >
                <label
                  tabindex="0"
                  (keydown)="checkboxKeydown($event)"
                  class="toggler-checkbox-container"
                >
                  <!-- <span class="sr-only">{{
                    checkboxState.active ? "checked" : "unchecked"
                  }}</span> -->
                  <input
                    type="checkbox"
                    class="toggler-checkbox-input"
                    formControlName="keepSignedIn"
                    id="keepSignedIn"
                    [checked]="checkboxState.active"
                    (change)="checkboxChanged()"
                  />
                  <i class="ico-soft"></i>
                  <!-- <span class="sr-only">{{ title }}</span> -->
                </label>
                <span [innerHTML]="labels.login_form_keep_signed_in"></span>
                <div
                  class="info-btn-container"
                  tabindex="0"
                  (mouseover)="showTooltip = true"
                  (mouseout)="showTooltip = false"
                  (focus)="showTooltip = true"
                  (blur)="showTooltip = false"
                >
                  <i class="ico-info"></i>
                  <div class="tooltip-info down" [hidden]="!showTooltip">
                    <span
                      class="password-rule"
                      [innerHTML]="labels.login_form_keep_signed_in_tooltip"
                      aria-live="polite"
                    ></span>
                  </div>
                </div>
              </div>
              <button
                [attr.aria-label]="labels.login_form_button_aria_label"
                type="submit"
                class="btn btn-primary"
                [disabled]="!loginForm.valid"
              >
                {{ labels.login_form_button }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
