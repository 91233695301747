import { Component, OnInit } from '@angular/core';
import { UtilityService } from './../../services/utility.service';
import { Params } from '@angular/router';
// import { environment } from '../../../environments/environment';
import { SDKFactory } from 'src/app/services/sdk-factory.service';
import {
  AccountLinkingConsentData,
  AccountLinkingData,
  BrandItem,
  ErrorItem,
  UserProfile,
} from 'src/app/interfaces/ApplicationState';
import { firstValueFrom } from 'rxjs';
import { ResourceService } from './../../services/resources.service';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss'],
})
export class ConsentComponent implements OnInit {
  queryParams: Params | undefined;

  context: string = '';
  clientID: string = '';
  scope: string = '';
  uid: string = '';
  uidSignature: string = '';
  signatureTimestamp: string = '';

  oidcConsent: string = '';
  oidcApplicationName: string = '';

  code: string = '';

  thirdParty: string = '';
  gigyaUserKey: string = '';

  userProfile: UserProfile | undefined;

  config: any;
  labels: any = {};
  scopes: any | string[];
  logo: string = '';

  // brands: BrandItem[] = [];
  currentBrandName: string = 'Stellantis';
  currentPartnerName: string = '';

  constructor(
    private utilityService: UtilityService,
    private sdkFactory: SDKFactory,
    private resourceService: ResourceService
  ) {
   const scopes = sessionStorage.getItem('scopes')?.split(',');
    resourceService.getLabels().subscribe((res) => {
      if (res && res.labels) {
        this.labels = res.labels;
        if (scopes?.length && scopes.length > 0) {
          this.scopes = Array.from(new Set(scopes.map(e => this.labels[e] ? String(this.labels[e].charAt(0).toUpperCase() + this.labels[e].slice(1).toLowerCase()).trim() : ''))).filter(e => !!e);
        }
      }
    });
  }

  // loadLabels() {
  //   this.resourceService.loadLabels(true, 'US', 'en');
  // }

  async ngOnInit() {
    this.utilityService.showLoader();

    if (!this.config) {
      this.config = await firstValueFrom(this.resourceService.getConfig());
    }
    // console.log(this.config);

    if (this.config && this.config.config) {
      this.getBrandAndPartner();
      this.logo = this.labels.consent_logo || this.config.consent_logo;

      this.queryParams = this.utilityService.getQueryParams();

      this.code = this.queryParams['code'];

      if (this.code) {
        this.utilityService.hideLoader();
        // TODO: che fare in caso di redirect_uri non salvata su sessione storage?
        window.location.href =
          sessionStorage.getItem('redirect_uri')?.toString() +
          '?code=' +
          this.code;
      } else {
        this.context = this.queryParams['context'];
        this.clientID = this.queryParams['clientID'];
        this.scope = this.queryParams['scope']?.replaceAll('+', ' ');

        if (!this.context || !this.clientID || !this.scope) {
          this.utilityService.hideLoader();
          // window.location.href =
          //   environment.gigyaConfig.gigyaAuthorizeEndpoint;
          window.location.href = this.config.config.gigyaAuthorizeEndpoint;
        } else {
          this.uid = this.queryParams['UID'];
          this.uidSignature = this.queryParams['UIDSignature'];
          this.signatureTimestamp = this.queryParams['signatureTimestamp'];

          // this.thirdParty = environment.gigyaConfig.thirdParty;
          this.thirdParty = this.config.config.thirdParty;
          // this.gigyaUserKey = environment.gigyaConfig.userKey;
          this.gigyaUserKey = this.config.config.userKey;

          // this.oidcConsent = environment.gigyaConfig.OIDCConsent;
          this.oidcConsent = this.config.config.OIDCConsent;
          // this.oidcApplicationName =
          //   environment.gigyaConfig.OIDCApplicationName;
          this.oidcApplicationName = this.config.config.OIDCApplicationName;

          this.userProfile = await this.sdkFactory.getProfile(false);
          console.log('user profile', this.userProfile);

          if (!this.uid || !this.uidSignature || !this.signatureTimestamp) {
            this.utilityService.hideLoader();
            // const error = this.labels.errors.consent_login_error;
            this.goToErrorPage({
              title: this.labels.errors_consent_login_error_title,
              message: this.labels.errors_consent_login_error_message,
              icon: {
                type: 'error',
                class: 'ico-alert',
              },
            });
          }

          (!this.userProfile?.data?.applications ||
            (this.userProfile?.data?.applications &&
              !this.userProfile.data.applications[this.oidcApplicationName])) &&
            this.sdkFactory.setProfile({
              data: { applications: { [this.oidcApplicationName]: true } },
            });

          this.utilityService.hideLoader();
        }
      }
    } else {
      this.utilityService.hideLoader();
      // const error = this.labels.errors.consent_login_error;
      this.goToErrorPage({
        title: this.labels.errors_consent_login_error_title,
        message: this.labels.errors_consent_login_error_message,
        icon: {
          type: 'error',
          class: 'ico-alert',
        },
      });
    }
  }

  getBrandAndPartner(): void {
    this.currentBrandName = this.utilityService.getBrandName(
      this.config.config
    );

    // this.brands = this.config.config.gwp_brand_urls;

    // const currentBrand = sessionStorage.getItem('brand');

    // if (this.brands && currentBrand) {
    //   const brandObj = this.brands.find((item: any) => item.name === currentBrand);

    //     if (brandObj) {
    //       this.currentBrandName = brandObj.title;
    //     }
    // }

    this.currentPartnerName = this.config.config.partnerName;

    if (!this.currentPartnerName) {
      const partner = sessionStorage.getItem('partner');

      if (partner) {
        this.currentPartnerName = partner;
      }
    }
  }

  cancel(): void {
    // TODO: che fare in caso di redirect_uri non salvata su sessione storage?
    window.location.href =
      sessionStorage.getItem('redirect_uri')?.toString() + '?mode=userCancel';
  }

  async authorize() {
    const alDataObj: AccountLinkingData = {
      context: this.context,
      clientID: this.clientID,
      scope: this.scope,
      UID: this.uid,
      UIDSignature: this.uidSignature,
      signatureTimestamp: this.signatureTimestamp,
      thirdParty: this.thirdParty,
    };

    let res;

    try {
      this.utilityService.showLoader();
      res = await this.sdkFactory.postAccountLinking(alDataObj);

      if (res) {
        const proxyPageUrl =
          '/exve/proxy.html?mode=' +
          res.mode +
          '&consent=' +
          JSON.stringify(res.consentObj) +
          '&sig=' +
          res.signature +
          '&userKey=' +
          this.gigyaUserKey +
          '&context=' +
          this.context;

        const appId = sessionStorage.getItem('appId') || '';
        const alcDataObj: AccountLinkingConsentData = {
          appId,
          tcVersion: 'v1.0',
        };

        res = await this.sdkFactory.postAccountLinkingConsent(alcDataObj);

        if (res && res.status === 'success') {
          if (
            this.userProfile &&
            this.userProfile.preferences &&
            this.userProfile.preferences[this.oidcConsent] &&
            this.userProfile.preferences[this.oidcConsent].isConsentGranted
          ) {
            this.utilityService.hideLoader();
            window.location.href = proxyPageUrl;
          } else {
            // const data: any = {
            //   preferences: {},
            // };

            // data.preferences[this.oidcConsent] = {
            //   isConsentGranted: true,
            // };
            // res = await this.sdkFactory.setProfile(data);
            res = await this.sdkFactory.setProfile({
              preferences: { [this.oidcConsent]: { isConsentGranted: true } },
            });

            if (res) {
              this.utilityService.hideLoader();
              window.location.href = proxyPageUrl;
            } else {
              this.utilityService.hideLoader();
              this.goToErrorPage(undefined);
            }
          }
        } else {
          this.utilityService.hideLoader();
          this.goToErrorPage(undefined);
        }
      } else {
        this.utilityService.hideLoader();
        this.goToErrorPage(undefined);
      }
    } catch (error: any) {
      console.log(error);
      this.utilityService.hideLoader();
      this.goToErrorPage(undefined);
    }
  }

  goToErrorPage(errorItem: ErrorItem | undefined) {
    if (!errorItem) {
      // const error = this.labels.errors.consent_request_error;
      errorItem = {
        title: this.labels.errors_consent_request_error_title,
        message: this.labels.errors_consent_request_error_message,
        icon: {
          type: 'error',
          class: 'ico-alert',
        },
      };
    }

    this.utilityService.goToErrorPage(errorItem);
  }
}
