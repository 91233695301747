<div
  cdkTrapFocus
  class="modal fade"
  tabindex="-1"
  role="dialog"
  id="choose-brand-modal"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  [ngClass]="{ show: utilityService.showChooseBrandModal }"
>
  <div class="modal-dialog choose-brand-modal" role="document">
    <div class="modal-content">
      <button
        #closeBtn
        type="button"
        class="btn btn-img close"
        (click)="closeModal()"
        [attr.aria-label]="utilityService.modalItems?.buttons?.close"
        aria-label=""
      >
        <span aria-hidden="true" class="ico-close"></span>
      </button>
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">
          {{ utilityService.modalItems?.title }}
        </h2>
      </div>
      <div class="modal-body">
        <div
          *ngFor="let brand of brands"
          class="brand-item"
          tabindex="0"
          [title]="brand.title"
          [attr.aria-label]="brand.title"
          (click)="closeModal(brand)"
          (keypress)="brandItemKeypress($event, brand)"
        >
          <div
            class="brand-logo"
            [ngStyle]="{ 'background-image': 'url(' + brand.icon + ')' }"
          ></div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          [attr.aria-label]="utilityService.modalItems?.buttons?.close"
          (click)="closeModal()"
        >
          {{ utilityService.modalItems?.buttons?.close }}
        </button>
      </div>
    </div>
  </div>
</div>
