import { Call } from '@angular/compiler';
import { Component } from '@angular/core';
import { ResourceService } from './../../services/resources.service';
import { firstValueFrom } from 'rxjs';
import { UtilityService } from 'src/app/services/utility.service';
import { ActivatedRoute } from '@angular/router';

declare const getCookieOpenModal: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  labels: any = {};
  showCookies: boolean = false;

  constructor(
    private resourceService: ResourceService,
    private utilityService: UtilityService
  ) {
    resourceService.getLabels().subscribe((res) => {
      if (res && res.labels) {
        this.labels = res.labels;
      }
    });
  }

  async ngOnInit() {
    if (!this.labels) {
      this.labels = await firstValueFrom(this.resourceService.getLabels());
      if (this.labels) {
        this.labels = this.labels.labels;
      }
    }
    const region = sessionStorage.getItem('region');

    if (region && region === 'emea' && this.labels) {
      // this.showCookies = this.labels.showCookies && this.labels.cookiesScript && this.labels.cookiesCss;
      this.showCookies = this.labels.cookiesScript && this.labels.cookiesCss;

      if (this.showCookies) {
        this.utilityService.setEmeaCookiesScript(this.labels);
      }
    }
  }

  emeaCookiesBtnClick() {
    if (this.showCookies) {
      getCookieOpenModal();
    }
  }
}
