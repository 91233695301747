<app-header></app-header>
<section class="page-container error-section">
  <div
    class="error-header d-flex justify-content-center align-items-center bg-dark"
    role="banner"
    aria-labelledby="error-title"
  >
    <h2 id="error-title" [innerHTML]="errorItem.title"></h2>
  </div>
  <div>
    <div class="d-flex justify-content-center p-3">
      <div
        class="error-body--container p-4 d-flex flex-column justify-content-center align-items-center shadow bg-body rounded"
      >
        <img
          class="logo"
          alt="Logo"
          *ngIf="errorItem.image"
          [src]="errorItem.image"
        />
        <span
          *ngIf="errorItem.icon"
          [class]="
            'icon ' +
            (errorItem.icon && errorItem.icon.class ? errorItem.icon.class : '')
          "
          [ngClass]="{
            alert: errorItem.icon.type === 'alert',
            error: errorItem.icon.type === 'error'
          }"
        ></span>
        <span
          class="font-subtitle p-3 text-center"
          [innerHTML]="errorItem.message"
        ></span>
        <div
          class="d-flex flex-row justify-content-center gap-4 p-4"
          *ngIf="errorItem.button"
        >
          <!-- <button class="btn btn-transparent" aria-label="Cancel">
            <span>CANCEL</span>
          </button> -->
          <button
            class="btn btn-primary"
            aria-label="Authorize"
            (click)="buttonClicked()"
          >
            <span>{{ errorItem.button.label }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
