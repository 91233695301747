import { Injectable } from '@angular/core';
import { WindowRef } from './window.service';

@Injectable({
  providedIn: 'root',
})
export class ApiConfigService {
  window: any = this.windowRef.nativeWindow();

  constructor(private windowRef: WindowRef) {}

  readonly config = {
    cognito_sdpr_params: {
      version: 'v1',
      sdpr_sign_service: 'execute-api',
      x_originator_type: 'web',
      //emea INT mock
      lambda_function_base_url: 'https://authz.sdpr-01.fcagcv.com',
      lambda_function_api_key: 'qLYupk65UU1tw2Ih1cJhs4izijgRDbir2UFHA3Je',
      region: 'eu-west-1',
      sdpr_api_key: 'qLYupk65UU1tw2Ih1cJhs4izijgRDbir2UFHA3Je',
      sdpr_api_key_disclaimer: 'qLYupk65UU1tw2Ih1cJhs4izijgRDbir2UFHA3Je',
      sdpr_api_host: 'channels.sdpr-01.fcagcv.com',
      fcl_api_host: 'mfa.fcl-01.fcagcv.com',
      fcl_api_key: 'JWRYW7IYhW9v0RqDghQSx4UcRYRILNmc8zAuh5ys',
      mqtt_host: 'ahwxpxjb5ckg1-ats.iot.eu-west-1.amazonaws.com',
      identity_token_path: '/v2/cognito/identity/token',
      // lambda_function_base_url: local_configs[region].lambda_function_base_url,
      // lambda_function_api_key: local_configs[region].lambda_function_api_key,
      // region: local_configs[region].region,
      // sdpr_api_key: local_configs[region].sdpr_api_key,
      // sdpr_api_key_disclaimer: local_configs[region].sdpr_api_key_disclaimer,
      // sdpr_api_host: local_configs[region].sdpr_api_host,
      // fcl_api_host: local_configs[region].fcl_api_host,
      // fcl_api_key: local_configs[region].fcl_api_key,
      // mqtt_host: local_configs[region].mqtt_host,
      // identity_token_path: local_configs[region].identity_token_path,
    },
  };

  readonly api = {
    getCognitoIdentityToken: {
      path: this.config.cognito_sdpr_params.identity_token_path,
      method: 'POST',
      host: this.config.cognito_sdpr_params.lambda_function_base_url,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': this.config.cognito_sdpr_params.lambda_function_api_key,
        clientrequestid: this.getClientRequestId(),
        locale: 'gb_en',
        // this.window.digitalData.page.pageInfo
        //   .countryCode +
        // '_' +
        // this.window.digitalData.page.pageInfo.language,
        //'x-device-id':'',
        //'x-os-type':'',
        'x-originator-type': 'web',
        'x-clientapp-version': '0.0.1', //mock
        //this.window.global_config.app_version,
      },
    },
    mop: {
      url: 'https://api-web-integ.space.awsmpsa.com', //mock INT
    },
  };

  // Builds correct path according to API version, set in cognito_sdpr_params.version
  getCorrectPath() {
    return this.window.cognito_sdpr_params.version
      ? '/' +
          this.window.cognito_sdpr_params.version +
          '/cognito/identity/token'
      : '/cognito/identity/token';
  }

  getClientRequestId() {
    const crypto = window.crypto;
    return Array(16)
      .fill(0)
      .map(() =>
        crypto.getRandomValues(new Uint32Array(1))[0].toString(36).charAt(2)
      )
      .join('');
  }
}
