import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { SDKFactory } from '../services/sdk-factory.service';
import { UtilityService } from './../services/utility.service';

export const authGuard: CanActivateFn = async (route, state) => {
  const sdk = inject(SDKFactory);
  const router = inject(Router);
  // const utilityService = inject(UtilityService);
  try {
    const isLogged = await sdk.getProfile();
    if (isLogged) {
      return true;
    }
  } catch {}
  // router.navigate(['/login']);
  const region = sessionStorage.getItem('region');
  // const region = utilityService.getUrlParam('region');
  router.navigate(['/' + region + '/login']);
  return false;
};
