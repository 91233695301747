import { Component, OnInit } from '@angular/core';
import { CdkTrapFocus } from '@angular/cdk/a11y';
import { UtilityService } from 'src/app/services/utility.service';
import { BrandItem } from 'src/app/interfaces/ApplicationState';
import { SDKFactory } from 'src/app/services/sdk-factory.service';
// import { environment } from '../../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { ResourceService } from './../../services/resources.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [CdkTrapFocus],
})
export class LoginComponent implements OnInit {
  config: any;

  labels: any = {};
  // labelsLoaded: boolean = false;
  brands: BrandItem[] = [];
  currentBrandName: string = 'Stellantis';
  currentPartnerName: string = '';

  constructor(
    private cdkTrapFocus: CdkTrapFocus,
    private sdkFactory: SDKFactory,
    public utilityService: UtilityService,
    private resourceService: ResourceService
  ) {
    resourceService.getLabels().subscribe((res) => {
      if (res && res.labels) {
        this.labels = res.labels;

        // this.labelsLoaded = true;
      }
    });
  }

  async ngOnInit() {
    if (!this.config) {
      this.config = await firstValueFrom(this.resourceService.getConfig());
    }
    // console.log(this.config);

    if (!this.config || !this.config.config) {
      // const error = this.labels.errors.configuration_error;
      this.utilityService.goToErrorPage({
        title: this.labels.errors_configuration_error_title,
        message: this.labels.errors_configuration_error_message,
        icon: {
          type: 'error',
          class: 'ico-alert',
        },
      });
    }

    this.brands = this.config.config.gwp_brand_urls;

    this.currentBrandName = this.utilityService.getBrandName(
      this.config.config
    );

    // const currentBrand = sessionStorage.getItem('brand');

    // if (this.brands && currentBrand) {
    //   const brandObj = this.brands.find((item: any) => item.name === currentBrand);

    //     if (brandObj) {
    //       this.currentBrandName = brandObj.title;
    //     }
    // }

    if (this.config && this.config.config) {
      this.currentPartnerName = this.config.config.partnerName;
    }

    if (!this.currentPartnerName) {
      const partner = sessionStorage.getItem('partner');

      if (partner) {
        this.currentPartnerName = partner;
      }
    }
  }

  async socialLoginButtonClicked(provider: string) {
    // this.openChooseBrandModal(null);
    let res = await this.sdkFactory.socialLogin(provider);
    const status = parseInt(res.status);
    switch (status) {
      case 200:
        this.utilityService.afterLoginSuccess();
        break;
      case 206001:
        const regToken = res.errors[0].errors.regToken;
        this.openChooseBrandModal(regToken);
        break;
      default:
        this.openLoginErrorPopup();
        break;
    }

    // res.status === '206001' && this.openChooseBrandModal();
    // res.status === '0' && this.utilityService.afterLoginSuccess();
  }

  openLoginErrorPopup() {
    const openerElement = this.utilityService.openErrorPopup(
      'login',
      this.labels,
      this.closeErrorPopup
    );

    if (openerElement) {
      try {
        this.cdkTrapFocus.enabled = true;
      } catch (error) {
        console.log(error);
      }
    }
  }

  closeErrorPopup(value: boolean, openerElement: HTMLElement) {
    if (openerElement) {
      openerElement.focus();
      try {
        this.cdkTrapFocus.enabled = false;
      } catch (error) {
        console.log(error);
      }
    }
  }

  openChooseBrandModal(regToken = null) {
    const currentBrand = sessionStorage.getItem('brand');

    let brandObj: any = null;

    if (currentBrand && currentBrand !== 'stellantis') {
      if (this.brands) {
        brandObj = this.brands.find((item: any) => item.name === currentBrand);

        if (brandObj) {
          this.openBrandPage(brandObj, regToken);
        }
      }
    }

    if (!brandObj || !currentBrand || currentBrand === 'stellantis') {
      const openerElement = this.utilityService.openChooseBrandModal({
        title: this.labels?.choose_brand_modal_title,
        content: '',
        icon: '',
        level: '',
        buttons: {
          close: this.labels?.errors_login_error_button,
        },
        callback: (brand: BrandItem) => {
          // console.log(brand);
          this.openBrandPage(brand, regToken);
          // if (brand.url) {
          //   const countryCode = sessionStorage.getItem('country');
          //   const language = sessionStorage.getItem('language');

          //   if (countryCode && language) {
          //     // window.location.href =
          //     //   brand.link + '/' + countryCode + '/' + language + '/login';
          //     const baseUrl = `${brand.url}/${countryCode.toLowerCase()}/${language}`;
          //     regToken ? window.open(`${baseUrl}/local-register#provider=social&regToken=${regToken}`,'_blank') : window.open(`${baseUrl}/login`,'_blank');
          //   }
          // }

          if (openerElement) {
            openerElement.focus();
            try {
              this.cdkTrapFocus.enabled = false;
            } catch (error) {
              console.log(error);
            }
          }
        },
      });

      if (openerElement) {
        try {
          this.cdkTrapFocus.enabled = true;
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  openBrandPage(brand: any, regToken: any) {
    if (brand.url) {
      const countryCode = sessionStorage.getItem('country');
      const language = sessionStorage.getItem('language');

      if (countryCode && language) {
        // window.location.href =
        //   brand.link + '/' + countryCode + '/' + language + '/login';
        const baseUrl = `${brand.url}/${countryCode.toLowerCase()}/${language}`;
        regToken
          ? window.open(
              `${baseUrl}/local-register#provider=social&regToken=${regToken}`,
              '_blank'
            )
          : window.open(`${baseUrl}/login`, '_blank');
      }
    }
  }

  registerButtonClicked() {
    this.openChooseBrandModal(null);
  }

  // goToErrorPage() {
  //   this.utilityService.goToErrorPage({
  //     title: this.labels.errors_configuration_error_title,
  //     message: this.labels.errors_configuration_error_message,
  //     icon: {
  //       type: 'error',
  //       class: 'ico-alert',
  //     },
  //   });
  // }
}
