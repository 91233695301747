import { Component } from '@angular/core';
import { UtilityService } from 'src/app/services/utility.service';
import { ResourceService } from './../../services/resources.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  labels: any = {};

  constructor(
    private resourceService: ResourceService,
    private utilityService: UtilityService
  ) {
    resourceService.getLabels().subscribe((res) => {
      if (res && res.labels) {
        this.labels = res.labels;
      }
    });
  }
}
