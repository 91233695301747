import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ConsentComponent } from './components/consent/consent.component';
import { ErrorComponent } from './components/error/error.component';
import { authGuard } from './guards/auth.guard';
import { sdkInit } from './guards/sdk.guard';
import { ValidatePageComponent } from './components/validate-page/validate-page.component';
// import { ProxyPageComponent } from './components/proxy-page/proxy-page.component';

const routes: Routes = [
  {
    path: ':region',
    canActivateChild: [sdkInit],
    children: [
      // { path: 'validate', component: ValidatePageComponent },
      // { path: 'login', component: LoginComponent },
      // {
      //   path: 'consent',
      //   component: ConsentComponent,
      //   canActivate: [authGuard],
      // },
      // { path: 'error', component: ErrorComponent },
      // // { path: 'proxy', component: ProxyPageComponent, canActivate: [authGuard], pathMatch: 'full' },
      // { path: '', redirectTo: 'validate', pathMatch: 'full' },
      // { path: '**', redirectTo: 'error' },

      { path: 'validate', component: ValidatePageComponent },
      { path: 'login', component: LoginComponent },
      {
        path: 'consent',
        component: ConsentComponent,
        canActivate: [authGuard],
      },
      { path: 'error', component: ErrorComponent },
      // { path: 'proxy', component: ProxyPageComponent, canActivate: [authGuard], pathMatch: 'full' },
      // { path: '', redirectTo: ':region/validate', pathMatch: 'full' },
      // { path: ':region', redirectTo: ':region/validate', pathMatch: 'full' },
      // { path: '**', redirectTo: ':region/error' },
    ],
  },
  // { path: 'error', component: ErrorComponent },
  { path: '**', redirectTo: 'error' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
