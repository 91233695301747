<div class="footer-wrapper container">
  <div class="row">
    <div class="footer-left col-6">
      <img
        class="logo ng-scope"
        [alt]="labels.footer_stellantis_logo_alt"
        [src]="labels.footer_stellantis_logo"
      />
    </div>
    <div class="footer-right col-6">
      <button
        role="link"
        *ngIf="showCookies && labels.footer_cookie_policy_scripts_link_label"
        class="btn btn-transparent cookies"
        title="{{ labels.footer_cookie_policy_scripts_link_label }}"
        [attr.aria-label]="labels.footer_cookie_policy_scripts_link_label"
        (click)="emeaCookiesBtnClick()"
      >
        {{ labels.footer_cookie_policy_scripts_link_label }}
      </button>
    </div>
  </div>
</div>
