import { Injectable } from '@angular/core';
import { Params, Router, ActivatedRoute } from '@angular/router';
import { ErrorItem, ModalItems } from '../interfaces/ApplicationState';
import { GlobalService } from './global.service';
import { BrandItem } from 'src/app/interfaces/ApplicationState';
import { brandsStyles } from '../interfaces/BrandStyles';
// import { SpaceConfig } from '@sp400/core';
// import { environment } from './../../environments/environment.intg';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  showSpinner: boolean = false;
  private spinnerCounter: number = 0;

  showModal: boolean = false;
  showErrorPopup: boolean = false;
  showChooseBrandModal: boolean = false;
  modalItems: ModalItems | undefined;

  private openerElement: HTMLElement | null = null;

  constructor(
    private router: Router,
    private globalService: GlobalService,
    private route: ActivatedRoute
  ) {}

  showLoader(): void {
    this.showSpinner = true;
    this.spinnerCounter++;
  }

  hideLoader(): void {
    if (this.spinnerCounter > 0) {
      this.spinnerCounter--;
    }

    this.showSpinner = this.spinnerCounter > 0;
  }

  afterLoginSuccess() {
    console.debug('SEI CORRETTAMENTE LOGGATO!!');

    // this.router.navigateByUrl('/proxy?mode=afterLogin');
    window.location.href = '/exve/proxy.html?mode=afterLogin';
  }

  openModal(items: ModalItems): HTMLElement {
    this.modalItems = undefined;
    this.modalItems = items;
    this.showModal = true;

    this.openerElement = document.activeElement as HTMLElement;

    return this.openerElement;
  }

  closeModal(value?: boolean): void {
    this.showModal = false;
    if (this.modalItems?.callback) {
      this.modalItems?.callback(value);
    }
    this.openerElement = null;
  }

  private _openErrorPopup(items: ModalItems): HTMLElement {
    this.modalItems = undefined;
    this.modalItems = items;
    this.showErrorPopup = true;

    this.openerElement = document.activeElement as HTMLElement;

    return this.openerElement;
  }

  openErrorPopup(type: string, labels: any, callbackFn: any): HTMLElement {
    let message: string = '';
    let button: string = '';

    switch (type) {
      case 'login':
        message = labels.errors_login_error_message;
        button = labels.errors_login_error_button;
        break;
      default:
        message = labels.errors_generic_error_message;
        button = labels.errors_login_error_button;
        break;
    }

    const openerElement = this._openErrorPopup({
      title: '',
      content: message,
      icon: 'ico-error',
      level: 'error',
      buttons: {
        close: button,
      },
      callback: (value: any) => {
        callbackFn(value, openerElement);
      },
    });
    return openerElement;
  }

  closeErrorPopup(value?: boolean): void {
    this.showErrorPopup = false;
    if (this.modalItems?.callback) {
      this.modalItems?.callback(value);
    }
    this.openerElement = null;
  }

  openChooseBrandModal(items: ModalItems): HTMLElement {
    this.modalItems = undefined;
    this.modalItems = items;
    this.showChooseBrandModal = true;

    this.openerElement = document.activeElement as HTMLElement;

    return this.openerElement;
  }

  closeChooseBrandModal(brand?: BrandItem): void {
    this.showChooseBrandModal = false;
    if (this.modalItems?.callback) {
      this.modalItems?.callback(brand);
    }
    this.openerElement = null;
  }

  goToErrorPage(errorItem: ErrorItem) {
    this.globalService.setProp<ErrorItem>('errorItem', errorItem);
    let region = sessionStorage.getItem('region');
    if (region) {
      this.router.navigate(['/' + region + '/error']);
    }
  }

  getQueryParams(): Params {
    return this.route.snapshot.queryParams;
  }

  getBrandFromQueryParams(): string {
    let brand = '';
    const qParams = this.getQueryParamsFromUrl();

    if (qParams && qParams['brand']) {
      brand = qParams['brand'];

      if (!Object.keys(brandsStyles).includes(brand.toLowerCase())) {
        brand = 'stellantis';
      }
    }

    return brand;
  }

  getBrandFromHost(): string {
    let brand = '';
    const host = window.location.host;

    const segments = host.split('.');

    if (segments && segments.length > 1) {
      brand = segments[segments.length - 2].toLowerCase();
    }

    return brand;
  }

  getRegionFromPath(): string {
    let region = '';
    const pathname = window.location.pathname;

    const segments = pathname.split('/');

    if (segments && segments.length > 1) {
      region = segments[segments.length - 2];
    }

    return region;
  }

  getQueryParamsFromUrl(): any {
    let paramsObj = {};
    const href = window.location.href;

    const parts = href.split('?');

    if (parts && parts.length > 1) {
      const params = parts[1].split('&');
      paramsObj = params.reduce((obj: any, item: string, index: number) => {
        const itemParts = item.split('=');
        return {
          ...obj,
          [itemParts[0]]: itemParts[1],
        };
      }, {});
    }

    return paramsObj;
  }

  // getSpaceConfig(config: any): SpaceConfig {

  //   /* TODO: comporre l'oggetto SpaceConfig a partire dal config ottenuto da CMS */
  //   return environment.spaceConfig;
  // }

  getSpaceConfig(
    config: any,
    brand: string,
    region: string,
    country: string,
    language: string
  ): any {
    const spaceConfig = {
      monitoring: 'HIGH',
      credentials: {
        apiKey: config?.config.gigya_api_key,
        enableCLP: false,
        fcaCognito: {
          getCognitoIdentityToken: {
            path: config?.config.identity_token_path, // '/v2/cognito/identity/token',
            method: 'POST',
            host: config?.config.lambda_function_base_url, // 'https://authz.sdpr-01.intg.fcagcv.com',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': config?.config.lambda_function_api_key,
              locale: region === 'emea' ? 'gb_en' : 'us_en', // ??
              region: config?.config.lambda_region, // ??
              'x-originator-type': 'web',
              'x-clientapp-version': '1.0',
            },
          },
          cognito_sdpr_params: {
            fcl_api_host: config?.config.fcl_api_host, // 'https://mfa.fcl-01.intg.fcagcv.com',
            fcl_api_key: config?.config.fcl_api_key, // 'vNKSojwSpC2GDDMXILrW8rhb0IB55hO72f61aa4f',
            identity_token_path: config?.config.identity_token_path,
            lambda_function_api_key: config?.config.lambda_function_api_key, //'05lTLjIpDM80gyeG1w7o6asXZGJBQxMB77IxfOBQ',
            lambda_function_base_url: config?.config.lambda_function_base_url, // 'https://authz.sdpr-01.intg.fcagcv.com',
            mqtt_host: config?.config.mqtt_host, // 'a39nu3ubldsaht-ats.iot.eu-west-1.amazonaws.com',
            region: config?.config.lambda_region, // 'eu-west-1',
            sdpr_api_host: config?.config.sdpr_api_host, // 'https://channels.sdpr-01.intg.fcagcv.com',
            sdpr_api_key: config?.config.lambda_sdpr_api_key, // '05lTLjIpDM80gyeG1w7o6asXZGJBQxMB77IxfOBQ',
            sdpr_api_key_disclaimer:
              config?.config.lambda_function_api_key_disclaimer, // '22TW1MzCYY67w2YDNwLU053cfZFVvMwX6C2jiGa2',
            sdpr_sign_service: 'execute-api', // ??
            version: 'v1',
            x_originator_type: 'web',
          },
          mopApiUrl: config?.config.mopApiUrl, // 'https://api-web-integ.space.awsmpsa.com',
        },
      },
      brand: brand, // 'jeep', // ??
      appVersion: '1', // ??
      countryCode: country, // 'gb', // ??
      language: language, // 'en', // ??
    };
    console.log(config, 'spaceconfig');
    return spaceConfig;
  }

  getBrandName(config: any): string {
    let currentBrandName = 'Stellantis';
    const brands = config.gwp_brand_urls;

    const currentBrand = sessionStorage.getItem('brand');

    if (brands && currentBrand) {
      const brandObj = brands.find((item: any) => item.name === currentBrand);

      if (brandObj) {
        currentBrandName = brandObj.title;
      }
    }

    return currentBrandName;
  }

  setBrandStyle() {
    let brand = sessionStorage.getItem('brand');

    if (brand && Object.keys(brandsStyles).includes(brand.toLowerCase())) {
      const brandStyle = brandsStyles[brand.toLowerCase()];

      if (brandStyle) {
        Object.keys(brandStyle).forEach((key) => {
          const value = brandStyle[key];
          if (value) {
            document.documentElement.style.setProperty(key, value);
          }
        });
      }
    }
  }

  setEmeaCookiesScript(labels: any) {
    const region = sessionStorage.getItem('region');

    // if (region && region === 'emea' && labels && labels.showCookies && labels.cookiesScript && labels.cookiesCss) {
    if (
      region &&
      region === 'emea' &&
      labels &&
      labels.cookiesScript &&
      labels.cookiesCss
    ) {
      let scriptEle = document.createElement('script');

      scriptEle.setAttribute('src', labels.cookiesScript);
      scriptEle.setAttribute('type', 'text/javascript');
      scriptEle.setAttribute('async', 'true');

      let styleEle = document.createElement('link');

      styleEle.setAttribute('href', labels.cookiesCss);
      styleEle.setAttribute('rel', 'stylesheet');
      styleEle.setAttribute('type', 'text/css');

      // document.body.appendChild(scriptEle);
      // document.body.appendChild(styleEle);
      document.head.appendChild(scriptEle);
      document.head.appendChild(styleEle);
    }
  }

  // logout() {
  //   console.log('LOGOUT');
  // }
}
