import { Component, OnInit } from '@angular/core';
import { GlobalService } from './../../services/global.service';
import { ErrorItem } from '../../interfaces/ApplicationState';
import { ResourceService } from 'src/app/services/resources.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  errorItem: ErrorItem = {
    title: '',
    message: '',
    icon: {
      type: 'error',
      class: 'ico-alert',
    },
  };

  defaultErrorItem: ErrorItem = {
    title: '',
    message: '',
    icon: {
      type: 'error',
      class: 'ico-alert',
    },
  };

  constructor(
    private globalService: GlobalService,
    private resourceService: ResourceService
  ) {
    resourceService.getLabels().subscribe((res) => {
      if (res && res.labels) {
        this.defaultErrorItem.title = res.labels.errors_generic_error_title;
        this.defaultErrorItem.message = res.labels.errors_generic_error_message;
      }
    });
  }

  ngOnInit() {
    try {
      this.globalService
        .getProp<ErrorItem>('errorItem')
        .subscribe((item: ErrorItem) => {
          this.errorItem = item;
        });
    } catch (error) {
      this.errorItem = this.defaultErrorItem;
    }
  }

  buttonClicked() {
    if (this.errorItem.button && this.errorItem.button.callback) {
      this.errorItem.button.callback();
    }
  }
}
