import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SDKFactory } from '../../../services/sdk-factory.service';
import { UtilityService } from './../../../services/utility.service';
import { CdkTrapFocus } from '@angular/cdk/a11y';
import { ResourceService } from './../../../services/resources.service';

type CheckboxState = { active: boolean; text: string };
@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  providers: [CdkTrapFocus],
})
export class LoginFormComponent {
  loginForm: FormGroup;
  pwdInputType: string = 'password';
  checkboxState: CheckboxState = { active: false, text: 'off' };
  showTooltip: boolean = false;

  labels: any = {};
  constructor(
    private formBuilder: FormBuilder,
    private SDKFactoryService: SDKFactory,
    private utilityService: UtilityService,
    private cdkTrapFocus: CdkTrapFocus,
    private resourceService: ResourceService
  ) {
    resourceService.getLabels().subscribe((res) => {
      if (res && res.labels) {
        this.labels = res.labels;
      }
    });

    this.loginForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      password: [
        '',
        [Validators.required, Validators.pattern('^[^<>+\\|/*?]{8,16}$')],
      ],
      keepSignedIn: ['', []],
    });
  }

  onSubmit() {
    this.utilityService.showLoader();

    this.SDKFactoryService.login(
      this.loginForm.get('email')?.value,
      this.loginForm.get('password')?.value
    )
      .then((result: any) => {
        this.utilityService.hideLoader();
        if (result) {
          this.utilityService.afterLoginSuccess();
        } else {
          this.openErrorPopup();
        }
      })
      .catch((err: any) => {
        console.error(err);
        this.utilityService.hideLoader();
        this.openErrorPopup();
      });
  }

  openErrorPopup(): void {
    const openerElement = this.utilityService.openErrorPopup(
      'login',
      this.labels,
      this.closeErrorPopup
    );
    // const openerElement = this.utilityService.openErrorPopup({
    //   title: '',
    //   content: this.labels.errors_login_error_message,
    //   icon: 'ico-error',
    //   level: 'error',
    //   buttons: {
    //     close: this.labels.errors_login_error_button,
    //   },
    //   callback: (value: any) => {
    //     this.closeErrorPopup(value, openerElement);
    //   },
    // });

    if (openerElement) {
      try {
        this.cdkTrapFocus.enabled = true;
      } catch (error) {
        console.log(error);
      }
    }
  }

  closeErrorPopup(value: boolean, openerElement: HTMLElement) {
    if (openerElement) {
      openerElement.focus();

      try {
        this.cdkTrapFocus.enabled = false;
      } catch (error) {
        console.log(error);
      }
    }
  }

  togglepPwdShow(event: MouseEvent): void {
    event.stopPropagation();
    this.pwdInputType = this.pwdInputType === 'password' ? 'text' : 'password';
  }

  checkboxKeydown(event: KeyboardEvent) {
    if (event.code === 'Enter' || event.code === 'Space') {
      this.checkboxChanged();
    }
  }

  checkboxChanged() {
    this.checkboxState.active = !this.checkboxState.active;
    this.checkboxState.text = this.checkboxState.active ? 'on' : 'off';
  }
}
