<!-- <div class="header-wrapper content-wrapper row"> -->
<div class="header-wrapper container">
  <div class="row">
    <div class="header-left col-6">
      <img
        class="logo ng-scope"
        [alt]="labels.header_stellantis_logo_alt"
        [src]="labels.header_stellantis_logo"
      />
    </div>
    <div class="header-right col-6"></div>
  </div>
</div>
