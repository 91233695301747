import { Environment } from '../app/interfaces/environment';

export const environment: Environment = {
  isProduction: false,
  cmsDomain: '/exve/external/content/exve',
  sdpr: {
    emea: 'https://channels.sdpr-01.prep.fcagcv.com',
    na: 'https://channels.sdpr-02.prep.fcagcv.com',
    validatePath: '/v1/exve/application/validate',
  },
  // validateConfig: {
  //   appId: '46922530-6f4a-11ee-a169-795e3b50d511',
  // },
  // gigyaConfig: {
  //   apiKey:
  //     '3_yQdpprtoL9nLUUFg4B2XLYNrc1PFPPUtpoFbn6IbR-f67l1HaoqyIHgFZd1MHsz0',
  //   loginPage: '/login',
  //   consentPage: '/consent',
  //   errorPage: '/error',
  //   userKey: 'AL6yiphTbT1Q',
  //   thirdParty: 'EXVE_FCA',
  //   OIDCApplicationName: 'ExveV1OIDCallbrands',
  //   OIDCConsent: 'OIDCExve',
  //   gigyaAuthorizeEndpoint:
  //     'https://fidm.eu1.gigya.com/oidc/op/v1.0/4_coOkwo-v-yvgFBgAzve9kQ/authorize',
  // },
  // spaceConfig: {
  //   monitoring: 'HIGH',
  //   credentials: {
  //     apiKey: '4_q4KZE1zX56Bnn16pLD_olA',
  //     // apiKey: "4_coOkwo-v-yvgFBgAzve9kQ", // fornita da Morleo
  //     enableCLP: false,
  //     fcaCognito: {
  //       getCognitoIdentityToken: {
  //         path: '/v2/cognito/identity/token',
  //         method: 'POST',
  //         host: 'https://authz.sdpr-01.intg.fcagcv.com',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'x-api-key': '05lTLjIpDM80gyeG1w7o6asXZGJBQxMB77IxfOBQ',
  //           locale: 'gb_en',
  //           region: 'eu-west-1',
  //           'x-originator-type': 'web',
  //           'x-clientapp-version': '1.0',
  //         },
  //       },
  //       cognito_sdpr_params: {
  //         fcl_api_host: 'https://mfa.fcl-01.intg.fcagcv.com',
  //         fcl_api_key: 'vNKSojwSpC2GDDMXILrW8rhb0IB55hO72f61aa4f',
  //         identity_token_path: '/v2/cognito/identity/token',
  //         lambda_function_api_key: '05lTLjIpDM80gyeG1w7o6asXZGJBQxMB77IxfOBQ',
  //         lambda_function_base_url: 'https://authz.sdpr-01.intg.fcagcv.com',
  //         mqtt_host: 'a39nu3ubldsaht-ats.iot.eu-west-1.amazonaws.com',
  //         region: 'eu-west-1',
  //         sdpr_api_host: 'https://channels.sdpr-01.intg.fcagcv.com',
  //         sdpr_api_key: '05lTLjIpDM80gyeG1w7o6asXZGJBQxMB77IxfOBQ',
  //         sdpr_api_key_disclaimer: '22TW1MzCYY67w2YDNwLU053cfZFVvMwX6C2jiGa2',
  //         sdpr_sign_service: 'execute-api',
  //         version: 'v1',
  //         x_originator_type: 'web',
  //       },
  //       mopApiUrl: 'https://api-web-integ.space.awsmpsa.com',
  //     },
  //   },
  //   brand: 'jeep',
  //   appVersion: '1',
  //   countryCode: 'gb',
  //   language: 'en',
  // },
};
