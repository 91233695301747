import {
  Component,
  Renderer2,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { CdkTrapFocus } from '@angular/cdk/a11y';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
  providers: [CdkTrapFocus],
})
export class GenericModalComponent implements OnInit {
  @ViewChild('closeBtn') closeBtn: ElementRef | undefined;
  isOpen: boolean = false;

  constructor(
    private renderer: Renderer2,
    public utilityService: UtilityService
  ) {}

  ngOnInit(): void {}

  ngDoCheck() {
    if (this.utilityService.showModal && !this.isOpen) {
      this.isOpen = true;
      this.renderer.addClass(document.body, 'modal-open');

      setTimeout(() => this.closeBtn?.nativeElement.focus(), 0);
    }
  }

  closeModal(value: boolean) {
    this.utilityService.closeModal(value);
    this.renderer.removeClass(document.body, 'modal-open');
    this.isOpen = false;
  }
}
