import { Environment } from '../app/interfaces/environment';

// const jeepIntApiKey =
//   '3_fbXgLOoBza3HhW6uE1jXkj4LCuMBnbqV3q9JCCuvzBxCfOITc9e7yRbmPG-sUCaL';
// const exveApiKey = '4_coOkwo-v-yvgFBgAzve9kQ';

export const environment: Environment = {
  isProduction: false,
  cmsDomain: '/exve/external/content/exve',
  sdpr: {
    emea: 'https://channels.sdpr-01.intg.fcagcv.com',
    na: 'https://channels.sdpr-02.intg.fcagcv.com',
    validatePath: '/v1/exve/application/validate',
  },
  // validateConfig: {
  //   appId: '54436b20-740f-11ee-ab19-f34c3540ad40',
  // },
  // gigyaConfig: {
  //   apiKey: exveApiKey,
  //   loginPage: '/login',
  //   consentPage: '/consent',
  //   errorPage: '/error',
  //   userKey: 'AL6yiphTbT1Q',
  //   thirdParty: 'EXVE_FCA',
  //   OIDCApplicationName: 'ExveV1OIDCallbrands',
  //   OIDCConsent: 'OIDCExve',
  //   gigyaAuthorizeEndpoint:
  //     'https://fidm.eu1.gigya.com/oidc/op/v1.0/4_coOkwo-v-yvgFBgAzve9kQ/authorize?client_id=LXONSbgzIpfW7jzyOVqetA6h&response_type=code&redirect_uri=https%3A%2F%2Flocalhost&scope=openid%20email%20profile&nonce=1111',
  // },
  // spaceConfig: {
  //   monitoring: 'HIGH',
  //   credentials: {
  //     apiKey: exveApiKey,
  //     enableCLP: false,
  //     fcaCognito: {
  //       getCognitoIdentityToken: {
  //         path: '/v2/cognito/identity/token',
  //         method: 'POST',
  //         host: 'https://authz.sdpr-01.intg.fcagcv.com',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'x-api-key': '05lTLjIpDM80gyeG1w7o6asXZGJBQxMB77IxfOBQ',
  //           locale: 'gb_en',
  //           region: 'eu-west-1',
  //           'x-originator-type': 'web',
  //           'x-clientapp-version': '1.0',
  //         },
  //       },
  //       cognito_sdpr_params: {
  //         fcl_api_host: 'https://mfa.fcl-01.intg.fcagcv.com',
  //         fcl_api_key: 'vNKSojwSpC2GDDMXILrW8rhb0IB55hO72f61aa4f',
  //         identity_token_path: '/v2/cognito/identity/token',
  //         lambda_function_api_key: '05lTLjIpDM80gyeG1w7o6asXZGJBQxMB77IxfOBQ',
  //         lambda_function_base_url: 'https://authz.sdpr-01.intg.fcagcv.com',
  //         mqtt_host: 'a39nu3ubldsaht-ats.iot.eu-west-1.amazonaws.com',
  //         region: 'eu-west-1',
  //         sdpr_api_host: 'https://channels.sdpr-01.intg.fcagcv.com',
  //         sdpr_api_key: '05lTLjIpDM80gyeG1w7o6asXZGJBQxMB77IxfOBQ',
  //         sdpr_api_key_disclaimer: '22TW1MzCYY67w2YDNwLU053cfZFVvMwX6C2jiGa2',
  //         sdpr_sign_service: 'execute-api',
  //         version: 'v1',
  //         x_originator_type: 'web',
  //       },
  //       mopApiUrl: 'https://api-web-integ.space.awsmpsa.com',
  //     },
  //   },
  //   brand: 'jeep',
  //   appVersion: '1',
  //   countryCode: 'gb',
  //   language: 'en',
  // },
};
