import { Injectable } from '@angular/core';
import { ApplicationStateKeys } from '../interfaces/ApplicationState';
import { ReplaySubject } from 'rxjs';

type RootScope = { [k: string]: ReplaySubject<any> };

@Injectable()
export class GlobalService {
  constructor() {}

  rootScope: RootScope = {};

  hasProp(key: ApplicationStateKeys): boolean {
    return key in this.rootScope;
  }

  getProp<T>(key: ApplicationStateKeys): ReplaySubject<T> {
    return this.rootScope[key];
  }

  setProp<T>(key: ApplicationStateKeys, data: T) {
    if (!this.hasProp(key)) {
      this.rootScope[key] = new ReplaySubject<T>();
    }

    this.rootScope[key].next(data);
  }
}
