import {
  Component,
  Renderer2,
  OnInit,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { CdkTrapFocus } from '@angular/cdk/a11y';
import { UtilityService } from 'src/app/services/utility.service';
import { BrandItem } from 'src/app/interfaces/ApplicationState';
import { firstValueFrom } from 'rxjs';
import { ResourceService } from './../../services/resources.service';

@Component({
  selector: 'choose-brand-modal',
  templateUrl: './choose-brand-modal.component.html',
  styleUrls: ['./choose-brand-modal.component.scss'],
  providers: [CdkTrapFocus],
})
export class ChooseBrandModalComponent implements OnInit {
  @ViewChild('closeBtn') closeBtn: ElementRef | undefined;

  isOpen: boolean = false;
  brands: BrandItem[] = [];

  config: any;

  constructor(
    private renderer: Renderer2,
    public utilityService: UtilityService,
    private resourceService: ResourceService
  ) {}

  async ngOnInit() {
    if (!this.config) {
      this.config = await firstValueFrom(this.resourceService.getConfig());
    }

    this.brands = this.config.config.gwp_brand_urls;
  }

  ngDoCheck() {
    if (this.utilityService.showChooseBrandModal && !this.isOpen) {
      this.isOpen = true;
      this.renderer.addClass(document.body, 'modal-open');

      setTimeout(() => this.closeBtn?.nativeElement.focus(), 0);
    }
  }

  closeModal(brand?: BrandItem) {
    this.utilityService.closeChooseBrandModal(brand);
    this.renderer.removeClass(document.body, 'modal-open');
    this.isOpen = false;
  }

  brandItemKeypress(event: KeyboardEvent, brand: BrandItem) {
    if (event.code === 'Enter') {
      this.closeModal(brand);
    }
  }
}
