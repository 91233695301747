<app-header></app-header>
<div class="container login-page">
  <!-- <div class="page-content-wrapper row" *ngIf="labelsLoaded"> -->
  <div class="page-content-wrapper row">
    <div class="col-12 col-md-6 left-column">
      <div class="row">
        <div class="col">
          <h1
            class="login-title text-md-start text-center"
            [innerHTML]="labels.login_main_title"
          ></h1>
          <span
            class="login-subtitle text-md-start text-center"
            [innerHTML]="
              labels.login_main_description
                | replace
                  : ['{brand}', '{partner}']
                  : [currentBrandName, currentPartnerName]
            "
          >
          </span>
        </div>
      </div>
      <app-login-form></app-login-form>
      <div class="row register">
        <div class="col">
          <span class="font-body" [innerHTML]="labels.login_register"></span>
          <button
            class="btn btn-link"
            role="button"
            [attr.aria-label]="labels.login_register_btn"
            (click)="registerButtonClicked()"
          >
            {{ labels.login_register_btn }}
          </button>
        </div>
      </div>
      <div class="row social-login">
        <div class="col">
          <span
            class="font-body"
            [innerHTML]="labels.login_social_login"
          ></span>
          <div class="social-buttons">
            <button
              role="button"
              [attr.aria-label]="labels.login_social_facebook"
              class="btn btn-img facebook"
              (click)="socialLoginButtonClicked('facebook')"
            ></button>
            <button
              role="button"
              [attr.aria-label]="labels.login_social_x"
              class="btn btn-img twitter"
              (click)="socialLoginButtonClicked('twitter')"
            ></button>
            <button
              role="button"
              [attr.aria-label]="labels.login_social_google"
              class="btn btn-img google"
              (click)="socialLoginButtonClicked('googleplus')"
            ></button>
            <button
              role="button"
              [attr.aria-label]="labels.login_social_linkedin"
              class="btn btn-img linkedin"
              (click)="socialLoginButtonClicked('linkedin')"
            ></button>
            <button
              role="button"
              [attr.aria-label]="labels.login_social_yahoo"
              class="btn btn-img yahoo"
              (click)="socialLoginButtonClicked('yahoo')"
            ></button>
            <button
              role="button"
              [attr.aria-label]="labels.login_social_apple"
              class="btn btn-img apple"
              (click)="socialLoginButtonClicked('apple')"
            ></button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 right-column">
      <div class="row">
        <div class="col"></div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
<!-- <button type="button" class="btn btn-primary" (click)="openChooseBrandModal(null)">
  Apri Choose Brand
</button> -->
<!-- <button type="button" class="btn btn-primary" (click)="goToErrorPage()">
  Error Page
</button> -->
